// Resource
import { ReactComponent as AkouoLogoBlack } from 'RESOURCES/assets/akouo-logo-black.svg';

// Utils
import { IImageDimentions } from 'UTILS/captionerInterface';

const AkouoLogo = ({ height, width }: IImageDimentions) => {
  return <AkouoLogoBlack width={width} height={height} />;
};

AkouoLogo.defaultProps = {
  height: undefined,
  width: undefined,
};

export default AkouoLogo;
