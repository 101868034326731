import FullScreenWarning from 'COMPONENTS/FullScreenWarning/FullScreenWarning';

// Constant
import { CAPTIONER_LANGUAGE_RESOURCE, LANGUAGE_PREFIX } from 'CONSTANTS/captionerConstants';

// Service
import { useTranslation } from 'SERVICES/i18n';

// Error page if route not found
const PageNotFound = () => {
  const { t } = useTranslation(CAPTIONER_LANGUAGE_RESOURCE, LANGUAGE_PREFIX);
  return <FullScreenWarning label={t('PAGE_NOT_FOUND_404')} />;
};

export default PageNotFound;
