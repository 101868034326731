import utils from 'SERVICES/utils';
import { teamsCallActions, teamsCallSelector } from 'STORE/teamsCallSlice';
import { meetingSelector } from 'STORE/meetingSlice';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import logger from 'SERVICES/logger';
import {
  PARTICIPANT_EVENT_CHANGE,
  CAPTIONER_CONNECTING_STATUS,
  CAPTIONER_ACS_EVENTS,
  ENDCALL_REASON,
  SERVER_EVENT,
  PUBSUB_MESSAGE_TYPES,
  LANGUAGE_CAPTION_CODE,
  TIME_INTERVAL,
} from 'CONSTANTS/captionerConstants';
import { IRemoteParticipant } from 'CONSTANTS/common-interfaces';
import { Features } from '@azure/communication-calling';
import CognitiveService from 'SERVICES/CognitiveService';
import useActions from 'HOOKS/useActions';
import ACSCallClient from 'SERVICES/ACSCallClient';
import { IParticipant } from 'UTILS/captionerInterface';

const useTeamsCall = () => {
  const call = useSelector(teamsCallSelector.call);
  const teamsCallObj = useSelector(teamsCallSelector.teamsCallClient);
  const meetingLanguages = useSelector(meetingSelector.languages);
  const meetingParicipants = useSelector(meetingSelector.selectParticipant);
  const wsClient = useSelector(meetingSelector.selectWsClientObj);
  const callStartTime = useSelector(teamsCallSelector.callStartTime);

  const {
    setCallState,
    setCall,
    setCallEnded,
    setCallStartTime,
    setCallEndTime,
    setIsCaptionerActive,
  } = useActions(teamsCallActions);

  const speakerManipulationTimeOut = useRef<NodeJS.Timeout | null>(null);
  const remoteParticipants = useRef<Array<IRemoteParticipant>>([]);
  const [captionsData, setCaptionsData] = useState<any>(undefined);
  const [sessionTime, setSessionTime] = useState(0);

  const activeSpeakerRef = useRef<any>(undefined);
  const teamsCallCaptionsObjRef = useRef<any>(undefined);
  const activeSpokenLanguageRef = useRef<string | null>(null);
  const activeSpokenLanguageForTranslationsRef = useRef<string | null>(null);
  const meetingParticipantRef = useRef<any>(undefined);
  const noParticipantsPresent = useRef<boolean>(false);

  // Change or modify active speaker depending on conditions
  const manipulateActiveSpeaker = async () => {
    let activeSpeaker: IRemoteParticipant | undefined = undefined;

    // getting the current speaker information
    const currentSpeaker =
      remoteParticipants.current.find(
        (speaker) => speaker.userId === activeSpeakerRef.current?.userId
      ) || undefined;

    logger.debug(
      '[SPEAKER-ACTIVITY] Current Active Speaker: ',
      currentSpeaker?.displayName,
      'language: ',
      activeSpokenLanguageRef.current
    );

    // Case when no active speaker from begining of the call
    // Need to find the one who is not muted and is speaking
    if (!currentSpeaker) {
      const listOfActiveSpeakers = remoteParticipants.current.find(
        (speaker) => !speaker.isMuted && speaker.isSpeaking
      );

      logger.debug(
        '[SPEAKER-ACTIVITY] Here is the case when there is no one active speaker, hence setting active to ',
        listOfActiveSpeakers
      );
      activeSpeaker = listOfActiveSpeakers || undefined;
    } else if (currentSpeaker && currentSpeaker.isMuted) {
      // Check for rest of the unmuted speakers
      // If only one is there then make that person as active speaker
      const listOfUnmutedSpeakers = remoteParticipants.current.filter(
        (speaker) => !speaker.isMuted
      );
      if (listOfUnmutedSpeakers.length === 1) {
        logger.debug(
          `[SPEAKER-ACTIVITY] Only one unmuted speaker, hence setting active to ${listOfUnmutedSpeakers[0]?.displayName} without checking is speaking flag`
        );
        activeSpeaker = listOfUnmutedSpeakers[0];
      }
    }
    // Case when someone is already active speaker
    else if (currentSpeaker && !currentSpeaker.isMuted && !currentSpeaker.isSpeaking) {
      // If current speaker is muted
      //OR
      // unmuted but not speaking then we need to check for rest

      const listOfActiveSpeakers = remoteParticipants.current.find((speaker) => {
        return (
          !speaker.isMuted &&
          speaker.isSpeaking &&
          Date.now() - (speaker.speakingStartTime || 0) > 800
        );
      });

      logger.debug(
        '[SPEAKER-ACTIVITY] Here is the case (when current speaker is unmuted but not speaking) OR (current speaker is muted)',
        listOfActiveSpeakers
      );
      activeSpeaker = listOfActiveSpeakers || undefined;
    }

    if (
      activeSpeaker &&
      (!activeSpeakerRef.current ||
        (activeSpeakerRef.current && activeSpeakerRef.current.userId !== activeSpeaker.userId))
    ) {
      // Checking store value before setting new active speaker
      if (!meetingParticipantRef.current) {
        meetingParticipantRef.current = meetingParicipants;
      }

      logger.info('[SPEAKER-ACTIVITY] Active speaker changed to: ', activeSpeaker);

      // setting new active speaker in activeSpeaker refrence
      activeSpeakerRef.current = activeSpeaker;

      // fetching speaker details from store containing lanuguage selected but the speaker
      const activeMeetingParticipant = meetingParticipantRef.current?.find(
        (meetingParticipant: any) => meetingParticipant.userObjectId === activeSpeaker?.userId
      );

      logger.info(
        '[SPEAKER-ACTIVITY] active speaker spoken language: ',
        activeMeetingParticipant?.spokenLanguageCode
      );

      if (activeMeetingParticipant) {
        // Changing caption language to the active speaker spoken language
        if (activeMeetingParticipant.spokenLanguageCode !== activeSpokenLanguageRef.current) {
          activeSpokenLanguageRef.current = activeMeetingParticipant.spokenLanguageCode;
          activeSpokenLanguageForTranslationsRef.current =
            activeMeetingParticipant.spokenLanguageCode.split('-')[0];
          if (teamsCallCaptionsObjRef.current?.isCaptionsFeatureActive) {
            logger.debug(activeMeetingParticipant);
            logger.info(
              '[SPEAKER-ACTIVITY] Changing caption language to ',
              activeMeetingParticipant.spokenLanguageCode
            );

            // setting call language to the speaker spoken language as selected by the speaker
            await teamsCallCaptionsObjRef.current?.setSpokenLanguage(
              activeMeetingParticipant.spokenLanguageCode
            );

            logger.info(
              '[SPEAKER-ACTIVITY] active speaker: ',
              activeSpeakerRef.current?.displayName,
              'language: ',
              activeSpokenLanguageRef.current
            );
          }
        }
      }
    }

    // Clearing timeout if exist
    if (speakerManipulationTimeOut.current) {
      clearTimeout(speakerManipulationTimeOut.current);
    }
    return;
  };

  // update paricipant information
  const udpateParticipantInformation = async (participant: any, info: any) => {
    const participantId = utils.getId(participant.identifier);
    const participantIndex = remoteParticipants.current.findIndex(
      (remoteParticipant: any) => remoteParticipant.userId === participantId
    );
    // To store the event time when we start speaking
    const eventTime = Date.now();

    logger.debug(
      '[USE-TEAMS] udpateParticipantInformation participantId:',
      participantId,
      'If exist:',
      participantIndex
    );
    // if participant present then update information
    if (participantIndex !== -1) {
      logger.debug('[USE-TEAMS] UPDATING PARTICIPANT INFO', info, participant?.displayName);
      const member = remoteParticipants.current[participantIndex];
      // change infromation as per the event recieved
      switch (info) {
        case PARTICIPANT_EVENT_CHANGE.NAME_CHANGED:
          member.displayName = participant.displayName;
          break;
        case PARTICIPANT_EVENT_CHANGE.MUTE_CHANGED:
          logger.debug(
            '[SPEAKER-ACTIVITY] for: ',
            participant?.displayName,
            'is muted: ',
            participant?.isMuted
          );

          member.isMuted = participant.isMuted;
          break;
        case PARTICIPANT_EVENT_CHANGE.SPEAKING_CHANGED:
          logger.debug(
            '[SPEAKER-ACTIVITY] for: ',
            participant?.displayName,
            'is speaking: ',
            participant?.isSpeaking
          );

          // set speaking start time
          if (participant.isSpeaking) {
            member.speakingStartTime = eventTime;
          }

          member.isSpeaking = participant.isSpeaking;
          break;
        case PARTICIPANT_EVENT_CHANGE.STATE_CHANGED:
          member.state = participant.state;
          break;
        default:
          logger.debug('Invalid information');
      }
      remoteParticipants.current.splice(participantIndex, 1, member);
      remoteParticipants.current = [...remoteParticipants.current];

      await manipulateActiveSpeaker();

      // To check after few miliseconds whether speaker is active or background noise
      if (
        info === PARTICIPANT_EVENT_CHANGE.SPEAKING_CHANGED ||
        info === PARTICIPANT_EVENT_CHANGE.MUTE_CHANGED
      ) {
        speakerManipulationTimeOut.current = setTimeout(async () => {
          await manipulateActiveSpeaker();
        }, TIME_INTERVAL.NINEHUNDRED_MS);
      }
      return;
    }
  };

  // subscribe to remote participant events
  const subscribeToRemoteParticipant = async (participant: any) => {
    const participantId = utils.getId(participant.identifier);
    const findIndex = remoteParticipants.current.findIndex(
      (remoteParticipant: any) => remoteParticipant.userId === participantId
    );

    if (findIndex === -1) {
      // if not found Add details
      const meetingParticipant: IRemoteParticipant = {
        userId: utils.getId(participant.identifier),
        displayName: participant.displayName,
        isMuted: participant.isMuted,
        isSpeaking: participant.isSpeaking,
      };
      remoteParticipants.current = [...remoteParticipants.current, meetingParticipant];
    } else {
      remoteParticipants.current = [...remoteParticipants.current];
    }

    // subscribing to Participant Events
    participant.on(CAPTIONER_CONNECTING_STATUS.DISPLAY_NAME_CHANGED, async () => {
      await udpateParticipantInformation(participant, PARTICIPANT_EVENT_CHANGE.NAME_CHANGED);
    });

    participant.on(CAPTIONER_CONNECTING_STATUS.IS_SPEAKING_CHANGED, async () => {
      logger.debug('Speaking changed', participant?.displayName, participant?.isSpeaking);
      await udpateParticipantInformation(participant, PARTICIPANT_EVENT_CHANGE.SPEAKING_CHANGED);
    });
    participant.on(CAPTIONER_CONNECTING_STATUS.IS_MUTED_CHANGED, async () => {
      logger.debug('Mute status changed', participant?.displayName, participant?.isMuted);
      await udpateParticipantInformation(participant, PARTICIPANT_EVENT_CHANGE.MUTE_CHANGED);
    });

    participant.on(CAPTIONER_CONNECTING_STATUS.STATE_CHANGED, async () => {
      if (participant.state === CAPTIONER_CONNECTING_STATUS.CONNECTED) {
        logger.debug('Participant Connected', participant);
        await udpateParticipantInformation(participant, PARTICIPANT_EVENT_CHANGE.STATE_CHANGED);
      }
    });
  };

  // start captions
  const startTeamsCaptions = async () => {
    logger.info('Starting Captions Service');
    try {
      // start closed captions
      if (call?.state === CAPTIONER_CONNECTING_STATUS.CONNECTED) {
        logger.debug(`Starting captions with language: ${activeSpokenLanguageRef.current}`);
        await teamsCallCaptionsObjRef.current.startCaptions({
          spokenLanguage: activeSpokenLanguageRef.current,
        });
        setCallStartTime(new Date().toISOString());
      }
    } catch (error) {
      logger.error('Error: Unable to Join the call: ', error);
      throw error;
    }
  };

  // stop captions
  const stopTeamsCaptions = async () => {
    logger.info('Stoping Captions Service');
    try {
      // stop closed captions
      if (teamsCallCaptionsObjRef.current?.isCaptionsFeatureActive) {
        await teamsCallCaptionsObjRef.current.stopCaptions();
        const callEndTime: any = new Date();
        setCallEndTime(callEndTime.toISOString());
        if (callStartTime) {
          // calculating session time
          const startTime: any = new Date(callStartTime);
          const captionTime = (callEndTime - startTime) / 60000;
          setSessionTime(captionTime);
        }
      }
    } catch (error) {
      logger.error('Error: Unable to stop captions: ', error);
      throw error;
    }
  };

  // disconnect call
  const disconnectTeamsCall = async () => {
    await teamsCallObj?.hangupCall();
    await teamsCallObj?.disposeCall();
  };

  // set Active speaker spoken language
  useEffect(() => {
    logger.debug(
      meetingParicipants,
      activeSpeakerRef.current,
      activeSpokenLanguageForTranslationsRef.current,
      activeSpokenLanguageRef.current
    );
    if (meetingParicipants) {
      meetingParticipantRef.current = meetingParicipants;
    }

    // setting active speaker
    const findActiveSpeaker = meetingParicipants?.find(
      (meetingParticipant: any) =>
        meetingParticipant.userObjectId === activeSpeakerRef.current?.userId
    );

    logger.debug(
      `Current active spoken language, ${activeSpokenLanguageRef.current} current active user spoeken language ${findActiveSpeaker?.spokenLanguageCode}`
    );
    // if active speaker spoken language is not same as the previous speaker thn change Teams spoken language and caption language
    // changing Teams spoken langugae is important because for ACS closed Caption freature to work properly
    // Teams spoken language should be the same as the language in which initial caption is required
    if (
      findActiveSpeaker &&
      findActiveSpeaker.spokenLanguageCode !== activeSpokenLanguageRef.current
    ) {
      // changing caption language
      (async () => {
        // checking if caption feature is active or not then change language
        if (
          teamsCallCaptionsObjRef.current?.isCaptionsFeatureActive &&
          findActiveSpeaker.spokenLanguageCode.trim().length
        ) {
          logger.info('Changing caption language to ', findActiveSpeaker.spokenLanguageCode);
          await teamsCallCaptionsObjRef.current?.setSpokenLanguage(
            findActiveSpeaker.spokenLanguageCode
          );
        }
        if (findActiveSpeaker.spokenLanguageCode.trim().length) {
          logger.info('Changing caption language to ', findActiveSpeaker.spokenLanguageCode);
          activeSpokenLanguageRef.current = findActiveSpeaker.spokenLanguageCode;
          activeSpokenLanguageForTranslationsRef.current =
            findActiveSpeaker.spokenLanguageCode.split('-')[0];
        }
      })();
    }
  }, [meetingParicipants]);

  // Subscribe to call events
  useEffect(() => {
    if (call) {
      const FINAL_CAPTION = 'Final';
      const languageCodes = meetingLanguages.map((meetingLanguage: any) => meetingLanguage.code);
      // creating cognitive service object
      const cognitiveService = new CognitiveService();
      call.remoteParticipants.forEach(async (remoteParticipant: any) => {
        logger.debug('[USE-TEAMS] participant already in call:', remoteParticipant);
        await subscribeToRemoteParticipant(remoteParticipant);
      });

      // subscribing to call State event
      call.on(ACSCallClient.STATE_CHANGED, async () => {
        setCallState(call.state);
        if (call.callEndReason) {
          switch (call.callEndReason?.subCode) {
            case ENDCALL_REASON.CALL_ENDED:
              logger.debug('Meeting ended.');
              setCallEnded(ENDCALL_REASON.CALL_ENDED);
              break;
            case ENDCALL_REASON.HANGUP_CALL:
              logger.debug('Call Hangup.');
              setCallEnded(ENDCALL_REASON.HANGUP_CALL);
              break;
            case ENDCALL_REASON.PARTICIPANT_REMOVED:
            case ENDCALL_REASON.REMOVED_FROM_MEETING:
              logger.debug('Removed from meeting.');
              setCallEnded(ENDCALL_REASON.REMOVED_FROM_MEETING);
              break;
            case ENDCALL_REASON.CALL_REJECTED:
              logger.debug('Call Rejected.');
              setCallEnded(ENDCALL_REASON.CALL_REJECTED);
              break;
            case ENDCALL_REASON.CALL_DISCONNECTED:
              logger.debug('Call Disconnected.');
              setCallEnded(ENDCALL_REASON.CALL_DISCONNECTED);
              break;
            default:
              logger.info('Unknown callend reason', call.callEndReason);
              setCallEnded(call.callEndReason?.subCode);
          }
        }
        if (call.state === CAPTIONER_CONNECTING_STATUS.CONNECTED) {
          // Enabling captions feature
          logger.info('Enabling captions feature');
          const teamsCaptions = call.feature(Features.Captions);
          // teamsCaptions is a extended call object with captions feature object for the ACS closed captions
          teamsCallCaptionsObjRef.current = teamsCaptions.captions;

          // Defining handlers for captions
          const captionsReceivedHandler = async (data: any) => {
            if (data.resultType === FINAL_CAPTION) {
              logger.debug('Final captions from call', data);
              // getting speaker's userObjectId from caption data
              const userObjectId = utils.getId(data?.speaker?.identifier);
              // fetching speaker's data from store
              // using userObjectId to search for user beacuse we do bot get the userPrincipalName (email)
              // from ACS closed caption feature object, we only get user identifiers which consist of "userObjectId"
              const speakingParticipant = meetingParicipants.find(
                (participant: IParticipant) => participant.userObjectId === userObjectId
              );
              // Checking if only one language is there in meeting then no need to use cognitive service
              let captionsLanguage = data?.spokenLanguage.split('-')[0];
              captionsLanguage =
                captionsLanguage === LANGUAGE_CAPTION_CODE.ZH
                  ? LANGUAGE_CAPTION_CODE.LZH
                  : captionsLanguage;
              if (languageCodes.length === 1) {
                logger.debug(
                  '[INFO]: Only one language is there in meeting, hence not using the cognitive service'
                );
                const dataToSend = {
                  speaker: data.speaker?.displayName,
                  captions: {
                    [captionsLanguage]: data.captionText,
                  },
                  speakerEmail: speakingParticipant?.userId,
                  timestamp: data.timestamp.toISOString(),
                };
                setCaptionsData(dataToSend);
              } else {
                if (data.spokenLanguage) {
                  // If more than one language is there so we need to use service
                  // Here changing logic for getting captions and there translations
                  // Reason is there is a case when we speaker change but captions received the old speaker one
                  // Hence to get translations right, asking translation for the caption received language
                  const dataCaptions = await cognitiveService.getTranslatedData(
                    {
                      fromLanguage: captionsLanguage,
                      toLanguages: languageCodes
                        .filter((languageCode: string) => languageCode !== captionsLanguage)
                        .toString(),
                      textToTranslate: data.captionText,
                    },
                    data.speaker?.displayName
                  );
                  setCaptionsData({
                    ...dataCaptions,
                    speakerEmail: speakingParticipant?.userId,
                    timestamp: data.timestamp.toISOString(),
                  });
                }
              }
            }
          };

          // Defining the handler for isCaptionsActiveChanged event
          const captionsActiveChangedHandler = async () => {
            if (teamsCaptions.captions.isCaptionsFeatureActive) {
              logger.debug('Captions feature is active now');
              if (activeSpokenLanguageRef.current) {
                await teamsCallCaptionsObjRef.current?.setSpokenLanguage(
                  activeSpokenLanguageRef.current
                );
              }
              setIsCaptionerActive(teamsCaptions.captions.isCaptionsFeatureActive);
            } else {
              logger.debug('Captions feature is inactive now');
              setIsCaptionerActive(teamsCaptions.captions.isCaptionsFeatureActive);
            }
          };

          // Subscribing to events
          teamsCaptions.captions.on(
            CAPTIONER_ACS_EVENTS.CAPTIONS_ACTIVE_CHANGED,
            captionsActiveChangedHandler
          );
          teamsCaptions.captions.on(
            CAPTIONER_ACS_EVENTS.CAPTIONS_RECEIVED,
            captionsReceivedHandler
          );

          const spokenLanguages = teamsCaptions.captions.supportedSpokenLanguages;
          logger.debug(spokenLanguages);
        }
      });

      // subscribing to remote participant change event
      call.on(CAPTIONER_CONNECTING_STATUS.REMOTE_PARTICIPANTS_UPDATED, (eventData: any) => {
        logger.debug('[USE-TEAMS] remoteParticipantsUpdated event:', eventData);
        eventData.added.forEach(async (callParticipants: any) => {
          await subscribeToRemoteParticipant(callParticipants);
        });
        eventData.removed.forEach((callParticipants: any) => {
          logger.debug('Participant Disconnected', callParticipants);
          const id = utils.getId(callParticipants.identifier);
          remoteParticipants.current = remoteParticipants.current.filter(
            (remoteParticipant: any) => remoteParticipant.userId !== id
          );
          if (wsClient) {
            (async () => {
              wsClient?.sendMessageToServer(
                SERVER_EVENT.PARTICIPANT_REMOVED,
                {
                  removedUserObjectId: id,
                },
                PUBSUB_MESSAGE_TYPES.JSON
              );
            })();
          }
          // if no remote participant in meeting EndCall
          if (
            remoteParticipants.current.length === 0 &&
            call.callEndReason?.subCode === undefined
          ) {
            noParticipantsPresent.current = true;
            (async () => {
              await teamsCallObj?.hangupCall();
              await teamsCallObj?.disposeCall();
            })();
          }
        });
      });
    }
  }, [call]);

  // Once the call object is set from captioner compoent, all handling will appear here
  useEffect(() => {
    if (teamsCallObj) {
      teamsCallObj.subscribe(ACSCallClient.CALL_UPDATED, (addedCall: any) => {
        setCall(addedCall);
      });
    }

    // Cleanup
    return () => {
      (async () => {
        // if callObject exist then set callEnd code
        if (teamsCallObj) {
          setCallEnded(ENDCALL_REASON.LOCAL_CAEANUP);
        }
        await teamsCallObj?.hangupCall();
        await teamsCallObj?.disposeCall();
      })();
    };
  }, [teamsCallObj]);

  return {
    remoteParticipants: remoteParticipants.current,
    captionsData,
    activeSpeaker: activeSpeakerRef.current,
    sessionTime,
    noParticipantsPresent: noParticipantsPresent.current,
    startTeamsCaptions,
    stopTeamsCaptions,
    disconnectTeamsCall,
  };
};

export default useTeamsCall;
