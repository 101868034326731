import FullScreenWarning from 'COMPONENTS/FullScreenWarning/FullScreenWarning';
import {
  BROWSER_SUPPORTED,
  CAPTIONER_LANGUAGE_RESOURCE,
  LANGUAGE_PREFIX,
} from 'CONSTANTS/captionerConstants';
import Captioner from 'FEATURES/captioner/Captioner';
import PageNotFound from 'FEATURES/errorPage/PageNotFound';
import Redirect from 'FEATURES/redirect/Redirect';
import { browserName } from 'react-device-detect';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// add routes for the application
const CaptionerRoutes = () => {
  // translation service for error message
  const { t } = useTranslation(CAPTIONER_LANGUAGE_RESOURCE, LANGUAGE_PREFIX);
  // checking for supported browser
  // if browser not supported then show full screen warning
  if (!BROWSER_SUPPORTED.includes(browserName))
    return <FullScreenWarning label={t('UNSUPPORTED_BROWSER')} />;

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Captioner />} />
        <Route path="/:secret/:meetingId" element={<Redirect />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default CaptionerRoutes;
