import { ExclamationCircleIcon, Flex, FlexItem, Text } from '@fluentui/react-northstar';
import HyperLink from 'COMPONENTS/hyperLink/HyperLink';
import { CAPTIONER_LANGUAGE_RESOURCE, LANGUAGE_PREFIX } from 'CONSTANTS/captionerConstants';
import { useTranslation } from 'react-i18next';

import 'COMPONENTS/subscriptionDirections/SubscriptionDirections.css';

const SubscriptionDirections = () => {
  const { t } = useTranslation(CAPTIONER_LANGUAGE_RESOURCE, LANGUAGE_PREFIX);

  return (
    <Flex vAlign="start" gap="gap.small" className="subscription-direction-container">
      <FlexItem>
        <ExclamationCircleIcon outline className="important-exclamation" />
      </FlexItem>
      <Flex column gap="gap.small">
        <Text content="Important" weight="bold" />
        <Text weight="semibold">
          {t('USER_SUBSCRIPTION_TEXT')}{' '}
          <HyperLink
            link={`${process.env.REACT_APP_AZURE_MARKETPLACE}`}
            label="Azure Marketplace"
          />{' '}
          {t('FREE_TRIAL_TEXT')}
        </Text>
      </Flex>
    </Flex>
  );
};

export default SubscriptionDirections;
