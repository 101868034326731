// Hook
import useActions from 'HOOKS/useActions';
import { useEffect, useState } from 'react';
import useAppInitializer from 'HOOKS/useAppInitializer';

// Service
import { registerErrorCallback } from 'SERVICES/serverAPI';

// Store
import { appActions } from 'STORE/appSlice';

// Resource
import ThemeProvider from 'RESOURCES/theme/ThemeProvider';

// Routes
import CaptionerRoutes from 'ROUTES/CaptionerRoutes';

const App = () => {
  const { isInitialized } = useAppInitializer();

  const setErrorTimestamp = useActions(appActions.setErrorTimestamp);

  // stores error DateTime
  const [errorValue, setErrorValue] = useState<number | undefined>(undefined);

  // error callback
  const errorCallback = () => {
    setErrorValue(Date.now);
  };

  // set error timeStamp when error value is changed i.e., when error occured
  useEffect(() => {
    if (errorValue && setErrorTimestamp) {
      // set error timeStamp in store
      setErrorTimestamp();
    }
  }, [errorValue]);

  registerErrorCallback(errorCallback);

  if (!isInitialized) return null;

  return (
    <ThemeProvider>
      <CaptionerRoutes />
    </ThemeProvider>
  );
};

export default App;
