import { Flex, Text, ErrorIcon } from '@fluentui/react-northstar';

// renders a fullscreen warning with message
const FullScreenWarning = ({ label }: { label: string }) => {
  return (
    <Flex fill vAlign="center" hAlign="center" column gap="gap.medium">
      <ErrorIcon size="largest" />
      <Text content={label} size="large" />
    </Flex>
  );
};

export default FullScreenWarning;
