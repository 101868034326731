import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@fluentui/react-northstar';
import { appActions } from 'STORE/appSlice';

import 'COMPONENTS/snackbar/Snackbar.css';

const Snackbar = () => {
  const snackbar = useSelector((store: any) => store.app.snackbar);
  const timeoutId = useRef<any>();
  const dispatch = useDispatch();

  // clearTimeout to be used in distructor
  const clearTimeoutHandler = () => {
    if (timeoutId.current) clearTimeout(timeoutId.current);
  };

  // if snackbar is called then timeout is set to remove the snackbar in fixed ammount of time
  if (snackbar.show) {
    const timeout = setTimeout(() => dispatch(appActions.hideSnackbar()), snackbar.duration);
    timeoutId.current = timeout;
  }

  // used as distructor
  useEffect(() => {
    return clearTimeoutHandler;
  }, []);

  if (snackbar.show) {
    return (
      <div className="snackbar">
        <Alert
          className="snackbar-alert"
          fitted
          content={snackbar.message}
          {...{ [snackbar.type]: true }}
        />
      </div>
    );
  }
  return null;
};

export default Snackbar;
