import { AcceptIcon, Avatar, CloseIcon, Flex, MicOffIcon, Text } from '@fluentui/react-northstar';
import { Fragment } from 'react';

// Constant
import {
  CAPTIONER_LANGUAGE_RESOURCE,
  ENDCALL_REASON,
  LANGUAGE_PREFIX,
} from 'CONSTANTS/captionerConstants';
import { CALL_STATUS, CAPTIONER_AVTAR_STATUS_COLOR } from 'CONSTANTS/enums';

// Hook
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Service
import logger from 'SERVICES/logger';
import { useTranslation } from 'react-i18next';

// Store
import { teamsCallSelector } from 'STORE/teamsCallSlice';

// Utils
import { ICaptionerStatusIcon } from 'UTILS/captionerInterface';

const CaptionerStatus = () => {
  const { t } = useTranslation(CAPTIONER_LANGUAGE_RESOURCE, LANGUAGE_PREFIX);

  // getting callState and endCall values from store
  const callState = useSelector(teamsCallSelector.callState);
  const callEnded = useSelector(teamsCallSelector.callEnded);

  const [captionerStatusIcon, setCaptionerStatusIcon] = useState<ICaptionerStatusIcon>({
    color: CAPTIONER_AVTAR_STATUS_COLOR.DISCONNECTED,
    icon: <CloseIcon />,
  });
  const [captionerStateMessage, setCaptionerStateMessage] = useState(t('CAPTIONER_NOT_CONNECTED'));

  // To handle captioner connecting status
  useEffect(() => {
    if (callState) {
      // to change captioner Status on UI
      switch (callState) {
        case CALL_STATUS.CONNECTING:
          setCaptionerStatusIcon({
            color: CAPTIONER_AVTAR_STATUS_COLOR.WARNING,
          });
          setCaptionerStateMessage(t('CAPTIONER_CONNECTING'));
          break;
        case CALL_STATUS.IN_LOBBY:
          setCaptionerStatusIcon({
            color: CAPTIONER_AVTAR_STATUS_COLOR.WARNING,
          });
          setCaptionerStateMessage(t('CAPTIONER_CONNECTING'));
          break;
        case CALL_STATUS.CONNECTED:
          setCaptionerStatusIcon({
            color: CAPTIONER_AVTAR_STATUS_COLOR.CONNECTED,
            icon: <AcceptIcon />,
          });
          setCaptionerStateMessage(t('CAPTIONER_CONNECTED'));
          break;
        case CALL_STATUS.DISCONNECTED:
          if (callEnded !== ENDCALL_REASON.CALL_REJECTED) {
            setCaptionerStatusIcon({
              color: CAPTIONER_AVTAR_STATUS_COLOR.DISCONNECTED,
              icon: <CloseIcon />,
            });
            setCaptionerStateMessage(t('CAPTIONER_DISCONNECTED'));
          }
          break;
        default:
          logger.info('Unknown Call State: ', callState);
      }
    }
  }, [callState]);

  return (
    <Fragment>
      <Flex gap="gap.medium" vAlign="center" id="captioner-status-visualizer">
        <Avatar
          name={process.env.REACT_APP_CAPTIONER_NAME}
          status={captionerStatusIcon}
          size="large"
        />

        <Flex vAlign="center">
          <Text content={process.env.REACT_APP_CAPTIONER_NAME} size="large" />
          <MicOffIcon size="medium" />
        </Flex>
      </Flex>
      <Flex id="captioner-status-display">
        <Text content={captionerStateMessage} size="large" align="center" />
      </Flex>
    </Fragment>
  );
};

export default CaptionerStatus;
