import { Button, ClosedCaptionsIcon, Flex, Loader } from '@fluentui/react-northstar';

// Constant
import {
  CAPTIONER_LANGUAGE_RESOURCE,
  ENDCALL_REASON,
  LANGUAGE_PREFIX,
} from 'CONSTANTS/captionerConstants';

// Hook
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Service
import { useTranslation } from 'react-i18next';

// Store
import { teamsCallSelector } from 'STORE/teamsCallSlice';

// Utils
import { IControlCaptions } from 'UTILS/captionerInterface';

import 'COMPONENTS/captionControls/CaptionControls.css';
import { CALL_STATUS } from 'CONSTANTS/enums';

const CaptionControls = (controlProps: IControlCaptions) => {
  const { startCaptions, stopCaptions, isDisabled } = controlProps;
  const { t } = useTranslation(CAPTIONER_LANGUAGE_RESOURCE, LANGUAGE_PREFIX);

  const callEnded = useSelector(teamsCallSelector.callEnded);
  const callState = useSelector(teamsCallSelector.callState);
  const isCaptionerActive = useSelector(teamsCallSelector.isCaptionerActive);

  const [captionLoading, setCaptionLoading] = useState(false);

  // control call actions (join/hangup)
  const controlCaptions = () => {
    if (!isCaptionerActive) {
      startCaptions();
    } else {
      stopCaptions();
    }
    setCaptionLoading(true);
  };

  // setting loading to false when isCaptionerActive is changed
  useEffect(() => {
    setCaptionLoading(false);
  }, [isCaptionerActive]);

  // setting variable values for enabling and disabeling button function
  const setDisabled =
    isDisabled || callEnded === ENDCALL_REASON.CALL_ENDED || callState !== CALL_STATUS.CONNECTED;

  return (
    <Flex id="caption-start-stop" gap="gap.medium">
      <Flex hAlign="center" className="button-container">
        {captionLoading && <Loader size="small" className="caption-button-loader" />}
        <Button
          icon={
            isCaptionerActive ? (
              <ClosedCaptionsIcon
                className={`stop-captions ${setDisabled && 'disabled'}`}
                outline
              />
            ) : (
              <ClosedCaptionsIcon className={`start-captions ${setDisabled && 'disabled'}`} />
            )
          }
          content={isCaptionerActive ? t('STOP_CAPTIONS') : t('START_CAPTIONS')}
          disabled={setDisabled}
          onClick={controlCaptions}
        />
      </Flex>
    </Flex>
  );
};

export default CaptionControls;
