import { createSlice } from '@reduxjs/toolkit';
import ACSCallClient from 'SERVICES/ACSCallClient';

interface IInitialState {
  call: undefined;
  callState: undefined;
  callEnded: undefined;
  deviceManager: undefined;
  teamsCallClient: ACSCallClient | undefined;
  callStartTime: string | undefined;
  callEndTime: string | undefined;
  isCaptionerActive: boolean;
}

const initialState: IInitialState = {
  call: undefined,
  callState: undefined,
  callEnded: undefined,
  deviceManager: undefined,
  teamsCallClient: undefined,
  callStartTime: undefined,
  callEndTime: undefined,
  isCaptionerActive: false,
};

const teamsCallSlice = createSlice({
  name: 'teamsCall',
  initialState,
  reducers: {
    setCall(state, action) {
      state.call = action.payload;
    },
    setCallState(state, action) {
      state.callState = action.payload;
    },
    setDeviceManager(state, action) {
      state.deviceManager = action.payload;
    },
    setTeamsCallClient(state, action) {
      state.teamsCallClient = action.payload;
    },
    setCallEnded(state, action) {
      state.callEnded = action.payload;
    },
    setCallStartTime(state, action) {
      state.callStartTime = action.payload;
    },
    setCallEndTime(state, action) {
      state.callEndTime = action.payload;
    },
    setIsCaptionerActive(state, action) {
      state.isCaptionerActive = action.payload;
    }
  },
});

export const teamsCallActions = { ...teamsCallSlice.actions };

export const teamsCallSelector = {
  call: (store: any) => store.teamsCall.call,
  callState: (store: any) => store.teamsCall.callState,
  deviceManager: (store: any) => store.teamsCall.deviceManager,
  teamsCallClient: (store: any) => store.teamsCall.teamsCallClient,
  callEnded: (store: any) => store.teamsCall.callEnded,
  callStartTime: (store: any) => store.teamsCall.callStartTime,
  callEndTime: (store: any) => store.teamsCall.callEndTime,
  isCaptionerActive: (store: any) => store.teamsCall.isCaptionerActive,
};

export default teamsCallSlice.reducer;
