import { IHyperLink } from "UTILS/captionerInterface";

import "COMPONENTS/hyperLink/HyperLink.css"

const HyperLink = ({ link, label }: IHyperLink) => {
  return (
    <a href={link} className="hyperLink" target="_blank" rel="noreferrer">
      {label}
    </a>
  );
};

export default HyperLink;
