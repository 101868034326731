import FullScreenLoader from 'COMPONENTS/FullScreenLoader/FullScreenLoader';
import { useParams, useNavigate } from 'react-router-dom';

// Constant
import { CAPTIONER_LANGUAGE_RESOURCE, LANGUAGE_PREFIX } from 'CONSTANTS/captionerConstants';

// Hook
import { useEffect } from 'react';
import useActions from 'HOOKS/useActions';

// Store
import { appActions } from 'STORE/appSlice';

// Service
import { useTranslation } from 'react-i18next';

/**
 * this page is the one intendet to be opend when user use the link provided on the Teams Application
 * this page will extract and stores the Secret and MeetingId attache in url
 * then navigate to the home page i.e, captioner page
 */
const Redirect = () => {
  const { secret, meetingId } = useParams();
  const navigate = useNavigate();
  const setMeetingId = useActions(appActions.setMeetingId);
  const setCaptionerSecret = useActions(appActions.setCaptionerSecret);
  const { t } = useTranslation(CAPTIONER_LANGUAGE_RESOURCE, LANGUAGE_PREFIX);

  // stores meetingId and secret in store then navigate to home page i.e, captioner page
  useEffect(() => {
    if (meetingId && secret) {
      setMeetingId(meetingId);
      setCaptionerSecret(secret);
      // navigate to captioner page
      navigate('/');
    }
  }, [meetingId, secret]);

  // renders fullscreen loader with redirect message
  return <FullScreenLoader label={t('REDIRECT_TO_CAPTIONER')} />;
};

export default Redirect;
