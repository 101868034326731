import { ExclamationCircleIcon, Flex, Image, Text } from '@fluentui/react-northstar';

// Constant
import {
  APP_ENV,
  CAPTIONER_LANGUAGE_RESOURCE,
  LANGUAGE_PREFIX,
} from 'CONSTANTS/captionerConstants';

// Resource
import AllowMicImgQA from 'RESOURCES/images/allow_microphone_QA.png';
import AllowMicImgProd from 'RESOURCES/images/allow_microphone_PROD.png';

// Service
import { useTranslation } from 'react-i18next';

import 'COMPONENTS/captionInstructions/CaptionInstructions.css';

const CaptionInstructions = () => {
  const { t } = useTranslation(CAPTIONER_LANGUAGE_RESOURCE, LANGUAGE_PREFIX);

  return (
    <Flex className="instructions">
      <Flex fill gap="gap.medium" column>
        <Text
          content={t('MICROPHONE_ACCESS_HEADING')}
          weight="semibold"
          align="center"
          size="large"
        />
        <Flex id="instructions-body" column gap="gap.medium">
          <Image
            src={process.env.REACT_APP_ENV_TYPE === APP_ENV.PROD ? AllowMicImgProd : AllowMicImgQA}
            className="allow-mic-image"
          />
          <Flex vAlign="center" gap="gap.small" className="user-exclamation">
            <ExclamationCircleIcon outline className="important-exclamation" />
            <Text
              content={t('MICROPHONE_ACCESS_MESSAGE')}
              weight="semibold"
              className="user-exclamation__text"
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CaptionInstructions;
