import { CalendarIcon, Flex, FlexItem, Text } from '@fluentui/react-northstar';
import { CAPTIONER_LANGUAGE_RESOURCE, LANGUAGE_PREFIX } from 'CONSTANTS/captionerConstants';
import { meetingSelector } from 'STORE/meetingSlice';
import { ISetLanguage } from 'UTILS/captionerInterface';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import "COMPONENTS/meetingInformation/MeetingInformation.css"

const MeetingInformation = () => {
  const { t } = useTranslation(CAPTIONER_LANGUAGE_RESOURCE, LANGUAGE_PREFIX);

  const meetingDetails = useSelector(meetingSelector.meetingDetails);

  const meetingLanguages = useMemo(() => {
    if (!meetingDetails?.languages) return;
    return meetingDetails?.languages
      .map((language: ISetLanguage) => {
        if (language.name) return language.name;
      })
      .join(', ');
  }, [meetingDetails]);

  return (
    <FlexItem>
      <Flex gap="gap.medium" column>
        <Flex vAlign="center" gap="gap.medium">
          <CalendarIcon size="larger" className="calendar-icon" />
          <Text content={meetingDetails?.subject} size="large" weight="semibold" />
        </Flex>
        <Flex gap='gap.smaller'>
          <Text content={t('MEETING_LANGUAGE')} weight="semibold" size="small" />
          <Text size="small">{meetingLanguages}</Text>
        </Flex>
      </Flex>
    </FlexItem>
  );
};

export default MeetingInformation;
