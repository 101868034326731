import { teamsCallSelector } from 'STORE/teamsCallSlice';
import { meetingSelector } from 'STORE/meetingSlice';
import { useSelector } from 'react-redux';
import logger from 'SERVICES/logger';
import { useEffect } from 'react';
import { ITeamsCall } from 'UTILS/captionerInterface';
import useSnackbar from 'HOOKS/useSnackbar';
import { SNACKBAR_TYPE } from 'CONSTANTS/enums';
import { useTranslation } from 'react-i18next';
import { CAPTIONER_LANGUAGE_RESOURCE, LANGUAGE_PREFIX } from 'CONSTANTS/captionerConstants';

const TeamsCall = ({ joinTeamsMeetingRequest, setError }: ITeamsCall) => {
  const showSnackbar = useSnackbar();
  const { t } = useTranslation(CAPTIONER_LANGUAGE_RESOURCE, LANGUAGE_PREFIX);
  // Call object from slice
  const teamsCallClient = useSelector(teamsCallSelector.teamsCallClient);

  // Meeting details from slice
  const meetingDetailsObj = useSelector(meetingSelector.meetingDetails);

  // Join teams call when message from WS received
  useEffect(() => {
    (async () => {
      if (joinTeamsMeetingRequest && teamsCallClient && meetingDetailsObj?.msTeamsMeetingUrl) {
        logger.info('Joining Teams Call');
        try {
          await teamsCallClient.joinMeeting({
            meetingLink: meetingDetailsObj?.msTeamsMeetingUrl,
          });
          // set joinCallError false to hide error rendered on page
          setError(false);
        } catch (error) {
          logger.error('Error: Unable to Join the call: ', error);
          // set joinCallError to render Error on UI
          showSnackbar({
            message: t('JOIN_CALL_ERROR'),
            type: SNACKBAR_TYPE.DANGER,
          });
          setError(true);
        }
      }
    })();
  }, [joinTeamsMeetingRequest, teamsCallClient]);

  return null;
};

export default TeamsCall;
