import { useSelector } from 'react-redux';

// Constant
import {
  CAPTIONER_LANGUAGE_RESOURCE,
  CAPTIONER_NAME,
  LANGUAGE_PREFIX,
} from 'CONSTANTS/captionerConstants';
import { ERROR, STATUS_CODE } from 'CONSTANTS/apiConstants';

// Hook
import useActions from 'HOOKS/useActions';
import { useEffect, useState } from 'react';

// Service
import logger from 'SERVICES/logger';
import { getMeetingDetails, fetchAccessToken, fetchSubscriptionDetails } from 'SERVICES/api';
import { useTranslation } from 'react-i18next';

// Store
import { appSelector } from 'STORE/appSlice';
import { meetingActions, meetingSelector } from 'STORE/meetingSlice';
import { teamsCallActions } from 'STORE/teamsCallSlice';
import ACSCallClient from 'SERVICES/ACSCallClient';

const useCaptioner = () => {
  const { t } = useTranslation(CAPTIONER_LANGUAGE_RESOURCE, LANGUAGE_PREFIX);

  const meetingId = useSelector(appSelector.meetingId);
  const captionerSecret = useSelector(appSelector.captionerSecret);
  const meetingInformation = useSelector(meetingSelector.meetingDetails);

  const { setMeetingDetails, setSubscriptionDetails } = useActions(meetingActions);
  const { setTeamsCallClient } = useActions(teamsCallActions);

  const [isAuthorized, setIsAutorised] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subcriptionLoading, setSubcriptionLoading] = useState(false);
  const [subcriptionFetchError, setSubcriptionFetchError] = useState<number | boolean>(false);
  const [authLabel, setAuthLabel] = useState(t('INVALID_LINK').toString());

  // function to call getMeeting API and stores data in store
  const fetchMeetingDetails = async () => {
    try {
      const meetingDetails = await getMeetingDetails(meetingId, captionerSecret);
      // store Meeting Details in store
      logger.debug('Meeting Details: ', meetingDetails);
      setMeetingDetails(meetingDetails);
      await createTeamsCall(meetingDetails);
      logger.debug('Meeting Details: ', meetingDetails);
    } catch (error: any) {
      logger.error('Error: unable to fetch meeting details', error);
      const { code } = error;
      // setting isAuthorised false with appropriate message

      switch (code) {
        // handling invalid likn error
        case ERROR.INVALID_MEETING_REQUEST:
        case ERROR.INVALID_CAPTIONER_SECRET:
          // set isAuthorised false
          setIsAutorised(false);
          // set message to be rendered
          setAuthLabel(t('INVALID_MEETING').toString());
          break;
        case ERROR.CAPTIONER_ALREADY_INMEETING:
          // set isAuthorised false
          setIsAutorised(false);
          // set message to be rendered
          setAuthLabel(t('CAPTIONER_ALREADY_IN_MEETING').toString());
          break;
        case ERROR.BAD_REQUEST:
          // set isAuthorised false
          setIsAutorised(false);
          // set message to be rendered
          setAuthLabel(t('SERVER_UNREACHABLE').toString());
          break;
        default:
          // set isAuthorised false
          setIsAutorised(false);
          // set message to be rendered
          setAuthLabel(t('SOMETHING_WENT_WRONG').toString());
      }
    }
    // set Loading false
    setLoading(false);
  };

  // function to get subscription details
  const fetchSubscriptionInformation = async () => {
    try {
      // fetching subscription details
      const subscriptionDetails = await fetchSubscriptionDetails(
        meetingInformation?.meetingId,
        meetingInformation?.subscriptionEmail,
        meetingInformation?.languages.length
      );
      logger.debug('Subsctiption Details: ', subscriptionDetails);
      // store Subscription Details in store
      setSubscriptionDetails(subscriptionDetails);
      // set error false
      setSubcriptionFetchError(false);
    } catch (error: any) {
      logger.error('Error: unalble to fetch Subscrtiption Information', error);
      if (error.resCode === STATUS_CODE.NOT_FOUND) setSubcriptionFetchError(error.resCode);
      else if (error.resCode === STATUS_CODE.FORBIDDEN) setSubcriptionFetchError(error.resCode);
      else setSubcriptionFetchError(true);
    }
    // stop loading
    setSubcriptionLoading(false);
  };

  // Creating MS Teams call
  const createTeamsCall = async (meetingDetails: any) => {
    const teamsCall = new ACSCallClient();
    await teamsCall.setupCall(
      process.env.REACT_APP_CAPTIONER_NAME || CAPTIONER_NAME,
      async () => await fetchAccessToken(meetingDetails?.captionerInformation?.captionerId)
    );
    setTeamsCallClient(teamsCall);
  };

  // Checking if the user uer is authorised or not
  useEffect(() => {
    if (meetingId !== '' && captionerSecret !== '') {
      // set isAuthorised true
      setIsAutorised(true);
    } else {
      // set isAuthorised false
      setIsAutorised(false);
      setAuthLabel(t('INVALID_LINK').toString());
    }
  }, [meetingId, captionerSecret]);

  // if authorised then get meeting details
  useEffect(() => {
    if (!isAuthorized) return;
    setLoading(true);
    // iife for fetchMeetingDetails
    (async () => {
      await fetchMeetingDetails();
    })();
  }, [isAuthorized]);

  // if meeting details is aquired will start fetching subscription information
  useEffect(() => {
    if (loading || !meetingInformation.subscriptionEmail) {
      setSubcriptionFetchError(STATUS_CODE.NOT_FOUND);
      return;
    }
    setSubcriptionLoading(true);
    // iife for subscription information
    (async () => {
      try {
        await fetchSubscriptionInformation();
      } catch (error) {
        logger.error('Error: Unable to fetch subscription information', error);
        throw error;
      }
    })();
  }, [loading, meetingInformation.subscriptionEmail]);

  return {
    authorization: { isAuthorized, authLabel },
    loading,
    subcriptionLoading,
    subcriptionFetchError,
  };
};

export default useCaptioner;
