import { IAxiosConstants } from 'UTILS/apiInterface';

export const API_METHODS = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
} as const;

export const COGNITIVE_API_HEADER = {
  'Ocp-Apim-Subscription-Key': process.env.REACT_APP_COGNITIVE_TRANSLATOR_KEY,
  'Ocp-Apim-Subscription-Region': process.env.REACT_APP_COGNITIVE_TRANSLATOR_REGION_LOCATION,
  'Content-type': 'application/json',
} as const;

export const COGNITIVE_API_PARAMS = {
  'api-version': '3.0',
} as const;

export const COGNITIVE_RESOPNSE_TYPE = {
  JSON: 'json',
  TEXT: 'text',
} as const;

export const API_URL = process.env.REACT_APP_API_URL;

export const AXIOS_CONSTANTS: Readonly<IAxiosConstants> = {
  REQUEST_HEADERS: {
    AUTH_TOKEN_KEY: 'Authorization',
    CONTENT_TYPE_JSON: 'application/json',
  },
  REQUEST_TIMEOUT: 30000,
};

// Teams context for API call
export const API_CONTEXT_CAPTIONER: Readonly<string> = 'CAPTIONER';

// API endpoints
export const API_ENDPOINTS = Object.freeze({
  CAPTIONS: '/captions/meeting',
  FETCH_ACCESS_TOKEN: '/captions/user/accessToken',
  FETCH_SUB_INFROMATION: '/subscription/getSubscriptionDetails',
});

// all API Response Status codes
export const STATUS_CODE = Object.freeze({
  SUCCESS: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED_USER: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
  PAYLOAD_TOO_LARGE: 413,

  // App Error
  SOMETHING_WENT_WRONG: 6000,
  NETWORK_NOT_REACHABLE: 6001,
  ERROR_SETTING_UP_REQUEST: 6002,
});

// all API Error codes
export const ERROR = Object.freeze({
  TOKEN_MISSING_OR_INVALID: 1001,
  MEETING_NOT_FOUND: 1003,
  INVALID_MEETING_REQUEST: 1008,
  CAPTIONER_ALREADY_INMEETING: 1010,
  INVALID_CAPTIONER_SECRET: 1011,
  SOMETHING_WENT_WRONG: 1004,
  BAD_REQUEST: 500,
});
