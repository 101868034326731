import serverAPI from 'SERVICES/serverAPI';
import { API_CONTEXT_CAPTIONER, API_ENDPOINTS } from 'CONSTANTS/apiConstants';

/**
 * Fetch Meeting details from backend.
 * @param {String} meetingId: Teams Meeting Id
 * @param {?String} code: Authentication code received from auth code flow
 * @returns response data
 */
export const getMeetingDetails = async (meetingId: string, secret: string, code?: any) => {
  const url = `${API_ENDPOINTS.CAPTIONS}/${meetingId}/${secret}/${API_CONTEXT_CAPTIONER}`;
  const response = await serverAPI.get(url, { params: { code } });
  return response.data;
};

/**
 * Creating AccessToken for captioner
 * @param {string} userId: captioner Id
 * @returns {object}
 */
export const fetchAccessToken = async (userId: string) => {
  const url = API_ENDPOINTS.FETCH_ACCESS_TOKEN;
  const response = await serverAPI.post(url, { userId });
  return response.data.token;
};

/**
 * fetching Subscription Details
 * @param {string} meetingId: teams MeetingId
 * @param {string} subscriptionEmail: emailId from which subscription is been taken
 * @param {number} langaugeCount: number of language configured in the meeting
 * @returns {object}
 */
export const fetchSubscriptionDetails = async (meetingId: string, subscriptionEmail: string, languageCount: number) => {
  const url = API_ENDPOINTS.FETCH_SUB_INFROMATION;
  const response = await serverAPI.post(url, { meetingId, subscriptionEmail, languageCount });
  return response.data;
}

