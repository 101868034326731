import { Flex, FlexItem, Text } from '@fluentui/react-northstar';
import HyperLink from 'COMPONENTS/hyperLink/HyperLink';

// Constant
import {
  CAPTIONER_LANGUAGE_RESOURCE,
  COPYRIGHT_TEXT,
  LANGUAGE_PREFIX,
} from 'CONSTANTS/captionerConstants';

// Service
import { useTranslation } from 'react-i18next';

// component to render HyperLink
const CaptionerFooter = () => {
  const { t } = useTranslation(CAPTIONER_LANGUAGE_RESOURCE, LANGUAGE_PREFIX);

  return (
    <Flex vAlign="center" space="between" gap="gap.medium">
      <FlexItem>
        <HyperLink
          link={`mailto:${process.env.REACT_APP_AKOUO_SUPPORT_EMAIL}`}
          label={t('SUPPORT')}
        />
      </FlexItem>
      <FlexItem>
        <HyperLink link={process.env.REACT_APP_AKOUO_PRIVACY_URL} label={t('PRIVACY_POLICIES')} />
      </FlexItem>
      <FlexItem>
        <Text content={COPYRIGHT_TEXT} />
      </FlexItem>
    </Flex>
  );
};

export default CaptionerFooter;
