import { createSlice } from '@reduxjs/toolkit';
import PubSubClient from 'SERVICES/PubSubClient';
import { IParticipant, ISetLanguage, ISpokenLanguage } from 'UTILS/captionerInterface';

interface IInitialState {
  meetingDetails: object;
  subscriptionDetails: object;
  meetingLanguage: ISetLanguage[];
  meetingSpokenLanguages: ISpokenLanguage[];
  selectedSpokenLanguages: ISpokenLanguage | object;
  participants: IParticipant[];
  wsClientObj: PubSubClient | null;
}

const initialState: IInitialState = {
  meetingDetails: {},
  subscriptionDetails: {},
  meetingLanguage: [],
  meetingSpokenLanguages: [],
  selectedSpokenLanguages: {},
  participants: [],
  wsClientObj: null,
};

const meetingSlice = createSlice({
  name: 'captionerMeeting',
  initialState,
  reducers: {
    setMeetingDetails: (state, action) => {
      const { participants } = action.payload;
      state.meetingDetails = action.payload;
      state.meetingLanguage = action.payload?.languages;
      state.meetingSpokenLanguages = action.payload?.spokenLanguages;
      if (participants?.length > 0) {
        state.participants = participants;
      }
    },
    setMeetingLanguages: (state, action) => {
      state.meetingLanguage = action.payload;
    },
    setSelectedSpokenLanguages: (state, action) => {
      state.selectedSpokenLanguages = action.payload;
    },
    updateParticipantMap: (state, action) => {
      const participants = action.payload;
      const prevState = state.participants;
      let participantArray: IParticipant[] = [];
      participants.forEach((participantData: IParticipant) => {
        const userArray = prevState.filter(
          (user: IParticipant) => user.userId !== participantData.userId
        );
        participantArray = [...userArray, participantData];
      });
      state.participants = participantArray;
    },
    setSubscriptionDetails: (state, action) => {
     state.subscriptionDetails = action.payload
    },
    setWsClient: (state, action) => {
      state.wsClientObj = action.payload
    }
  },
});

export default meetingSlice.reducer;

export const meetingActions = { ...meetingSlice.actions };

export const meetingSelector = {
  meetingDetails: (store: any) => store.captionerMeeting.meetingDetails,
  languages: (store: any) => store.captionerMeeting.meetingLanguage,
  selectedSpokenLanguages: (store: any) => store.captionerMeeting.selectedSpokenLanguages,
  selectParticipant: (store: any) => store.captionerMeeting.participants,
  subscriptionDetails: (store: any) => store.captionerMeeting.subscriptionDetails,
  selectWsClientObj: (store: any) => store.captionerMeeting.wsClientObj,
};

export const selectMeetingSpokenLanguages = (store: any) => { store.captionerMeeting.meetingSpokenLanguages; }

export const selectMeetingLanguage = (store: any) => store.captionerMeeting.meetingLanguage;
