import { CallBlockedIcon, Flex, Text } from '@fluentui/react-northstar';
import LoadingDots from 'COMPONENTS/loadingDots/LoadingDots';
import 'COMPONENTS/noNetworkOverlay/NoNetworkOverlay.css';
import { CAPTIONER_LANGUAGE_RESOURCE, LANGUAGE_PREFIX } from 'CONSTANTS/captionerConstants';
import { CONNECTION_STATUS } from 'CONSTANTS/enums';
import { appSelector } from 'STORE/appSlice';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Overlay component used when the tab looses internet connection
const NoNetworkOverlay = () => {
  const connectionStatus = useSelector(appSelector.connectionStatus);

  const { t } = useTranslation(CAPTIONER_LANGUAGE_RESOURCE, LANGUAGE_PREFIX);

  if (connectionStatus === CONNECTION_STATUS.ONLINE) return null;
  return (
    <Flex
      fill
      className="fullscreen-overlay"
      hAlign="center"
      vAlign="center"
      gap="gap.medium"
      column
    >
      <Flex vAlign="center" gap="gap.small">
        <CallBlockedIcon size="large" />
        <Text weight="semibold" color="brand" size="large" content={t('CONNECTION_LOST')} />
      </Flex>
      <Flex column hAlign="center">
        <Text weight="semibold" color="brand" size="large" content={t('ATTEMPT_TO_CONNECT')} />
      </Flex>
      <LoadingDots />
    </Flex>
  );
};

export default NoNetworkOverlay;
