const utils = {
  getId(identifier: string | any) {
    switch (identifier.kind) {
      case 'communicationUser':
        return identifier.communicationUserId;
      case 'phoneNumber':
        return identifier.phoneNumber;
      case 'microsoftTeamsUser':
        return identifier.microsoftTeamsUserId;
      case 'unknown':
        return identifier.id;
      default:
        return identifier.id; // can be undefined
    }
  },
};

export default utils;
