import { createSlice } from '@reduxjs/toolkit';
import { CONNECTION_STATUS, SNACKBAR_DURATION, SNACKBAR_TYPE } from 'CONSTANTS/enums';
import { ISnackBar } from 'UTILS/captionerInterface';

interface IInitialState {
    captionerSecret: string,
    meetingId: string,
    errorTimestamp: number,
    connectionStatus: CONNECTION_STATUS.ONLINE | CONNECTION_STATUS.OFFLINE,
    snackbar: ISnackBar,
}

const initialState: IInitialState = {
    captionerSecret: '',
    meetingId: '',
    errorTimestamp: 0,
    connectionStatus: CONNECTION_STATUS.ONLINE,
    snackbar: {
        show: false,
        message: 'Set message to show',
        duration: SNACKBAR_DURATION.MEDIUM,
        type: SNACKBAR_TYPE.INFO,
    },
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setCaptionerSecret(state, action) {
            state.captionerSecret = action.payload;
        },
        setMeetingId: (state, action) => {
            state.meetingId = action.payload;
        },
        setErrorTimestamp(state) {
            state.errorTimestamp = Date.now();
        },
        setConnectionStatus(state, action) {
            state.connectionStatus = action.payload
        },
        showSnackbar: (state, action) => {
            Object.assign(state.snackbar, { ...initialState.snackbar, ...action.payload, show: true });
        },
        hideSnackbar: (state) => {
            state.snackbar = initialState.snackbar;
        },
    },
});

export const appActions = { ...appSlice.actions };

export const appSelector = {
    errorTimestamp: (store: any) => store.app.errorTimestamp,
    captionerSecret: (store: any) => store.app.captionerSecret,
    meetingId: (store: any) => store.app.meetingId,
    connectionStatus: (store: any) => store.app.connectionStatus,
};
export default appSlice.reducer;
