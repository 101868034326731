// Hooks
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

// Service
import { appActions } from 'STORE/appSlice';

// Utils
import { ISnackBar } from 'UTILS/captionerInterface';

export default function useSnackbar() {
  const dispatch = useDispatch();
  return useMemo(
    () =>
      /**
       * @param {{message: string, duration: number, type: ('info' | 'danger' | 'success' | 'warning')}} args
       */
      (args: ISnackBar) =>
        dispatch(appActions.showSnackbar(args)),
    []
  );
}
