import { Flex } from "@fluentui/react-northstar";
import "COMPONENTS/loadingDots/LoadingDots.css"

const LoadingDots = () => {
    return(
        <Flex className="dot-flashing"></Flex>
    )
}

export default LoadingDots;
