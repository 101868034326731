import { configureStore } from '@reduxjs/toolkit';
import appSlice from 'STORE/appSlice';
import meetingSlice from 'STORE/meetingSlice';
import teamsCallSlice from 'STORE/teamsCallSlice';

const store = configureStore({
  reducer: {
    app: appSlice,
    captionerMeeting: meetingSlice,
    teamsCall: teamsCallSlice,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
