import { BrowserTypes } from 'react-device-detect';

export const APP_ENV = {
  DEV: 'dev',
  QA: 'qa',
  PROD: 'prod',
} as const;

export const BROWSER_SUPPORTED = [
  BrowserTypes.Chrome,
  BrowserTypes.Edge,
  BrowserTypes.EdgeChromium,
];

export const COPYRIGHT_TEXT = `© ${new Date().getFullYear()} Akouo Technologies Limited`;

export const CAPTIONER_LANGUAGE_RESOURCE = 'CAPTIONER';

export const LANGUAGE_PREFIX = { keyPrefix: 'COMMON' };

export const AUDIO_KEYWORD = 'audio';

export const PARTICIPANT_EVENT_CHANGE = {
  NAME_CHANGED: 'nameChanged',
  MUTE_CHANGED: 'muteChanged',
  SPEAKING_CHANGED: 'speakingChanged',
  STATE_CHANGED: 'stateChanged',
} as const;

export const CAPTIONER_CONNECTING_STATUS = {
  CONNECTED: 'Connected',
  STATE_CHANGED: 'stateChanged',
  REMOTE_PARTICIPANTS_UPDATED: 'remoteParticipantsUpdated',
  DISPLAY_NAME_CHANGED: 'displayNameChanged',
  IS_SPEAKING_CHANGED: 'isSpeakingChanged',
  IS_MUTED_CHANGED: 'isMutedChanged',
} as const;

export const CAPTIONER_ACS_EVENTS = {
  CAPTIONS_ACTIVE_CHANGED: 'CaptionsActiveChanged',
  CAPTIONS_RECEIVED: 'CaptionsReceived',
} as const;

export const SERVER_MESSAGES = {
  JOIN_MEETING: 'Join Meeting',
  NO_USERS_IN_MEETING: 'No users is this meeting, standby!',
} as const;

export const PUBSUB_MESSAGE_TYPES = {
  JSON: 'json',
  BINARY: 'binary',
  TEXT: 'text',
  PROTOBUF: 'protobuf',
} as const;

export const CAPTIONER_NAME = 'Akouo Captions';

export const SERVER_EVENT = {
  JOINED_MEETING: 'joinedMeeting',
  MEETING_ENDED: 'meetingEnded',
  CAPTIONS_STARTED: 'captionsStarted',
  CAPTIONS_ENDED: 'captionsEnded',
  REMOVED_FROM_MEETING: 'removedFromMeeting',
  PARTICIPANT_REMOVED: 'participantRemoved',
  SAVE_CAPTIONS: 'saveCaptions',
} as const;

export const CAPTIONER_MESSAGES = {
  MEETING_JOINED: 'Joined meeting now',
  LEFT_MEETING: 'Left meeting now',
  REMOVED_FROM_MEETING: 'Removed from meeting',
} as const;

export const ENDCALL_REASON = {
  HANGUP_CALL: 0,
  CALL_ENDED: 4097,
  CALL_REJECTED: 5854,
  REMOVED_FROM_MEETING: 5300,
  LOCAL_CAEANUP: 1111,
  PARTICIPANT_REMOVED: 5000,
  CALL_DISCONNECTED: 3101,
} as const;

export const CAPTIONER_EVENT = {
  CAPTION_STARTED: 'Captions Started',
  CAPTION_STOPPED: 'Captions Stopped',
} as const;

export const TIMER_COLOR = {
  RED: 'red',
  BLACK: 'black',
} as const;

export const TIME_INTERVAL = {
  THOUSAND_MS: 1000,
  HUNDRED_MS: 100,
  NINEHUNDRED_MS: 900,
} as const;

export const LANGUAGE_CAPTION_CODE = {
  ZH: 'zh',
  LZH: 'lzh',
} as const;
