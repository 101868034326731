// Constant
import { SERVER_MESSAGES } from 'CONSTANTS/captionerConstants';

// Hook
import useActions from 'HOOKS/useActions';
import { useState } from 'react';

// Service
import logger from 'SERVICES/logger';

// Store
import { meetingActions } from 'STORE/meetingSlice';

// hook to handel messages recieved on webSocket
const useMessages = () => {
  const { updateParticipantMap } = useActions(meetingActions);

  const [joinTeamsMeetingRequest, setJoinTeamsMeetingRequest] = useState(false);

  // handling server messages
  const handleServerMessage = (eventData: any) => {
    logger.info('Server-Message Handler: ', eventData);
    if (eventData.serverMessage === SERVER_MESSAGES.JOIN_MEETING) {
      logger.info(SERVER_MESSAGES.JOIN_MEETING);
      if (eventData.users) updateParticipantMap(eventData.users);
      // TODO: Handle this on BE
      if (!joinTeamsMeetingRequest) {
        setJoinTeamsMeetingRequest(true);
      }
    } else {
      // Change status to waiting
      // UI Change
      logger.info('Waiting :', eventData);
    }
  };

  // handling WS messages i.e., group messages
  const handleWsMessages = (eventData: any) => {
    // WS Messages
    logger.info('handleWsMessages', eventData);
    // user connected condition
    if (eventData?.userConnected) {
      const userInfo = eventData?.userConnected;
      // updating user map
      updateParticipantMap([
        { userId: userInfo?.userId, userObjectId: userInfo?.userObjectId, spokenLanguageCode: '' },
      ]);
    }
  };

  // handling Teams speaker language change
  const handleTeamsSpeakerLanguage = (eventData: any) => {
    logger.info(eventData);
    // updating user map
    updateParticipantMap([eventData]);
  };

  return {
    handleServerMessage,
    handleWsMessages,
    handleTeamsSpeakerLanguage,
    joinTeamsMeetingRequest,
  };
};

export default useMessages;
