import logger from 'SERVICES/logger';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import { CallClient, Call, CallAgent, TeamsMeetingLinkLocator } from '@azure/communication-calling';
// import acsUtils from './acsUtils';

class ACSCallClient {
  static CALL_UPDATED = 'callsUpdated';

  static STATE_CHANGED = 'stateChanged';

  static CALL_STATE = {
    none: 'None',
    connected: 'Connected',
    disconnected: 'Disconnected',
    other: 'Other',
  };

  private listeners: Map<any, any> | null = new Map();
  private callClient: CallClient | undefined;
  private tokenCredential: AzureCommunicationTokenCredential | undefined;
  private callAgent: CallAgent | undefined;
  private call: Call | undefined | null;

  /**
   *
   * @param displayName name with which call agent will be created
   * @param accessTokenApi Backend API for getting access token
   */
  async setupCall(displayName: string, accessTokenApi: any) {
    try {
      this.callClient = new CallClient();
      this.tokenCredential = new AzureCommunicationTokenCredential({
        refreshProactively: true,
        tokenRefresher: async () => accessTokenApi(),
      });
      this.callAgent = await this.callClient.createCallAgent(this.tokenCredential, {
        displayName,
      });
      this.callAgent.on('callsUpdated', async (eventData) => {
        logger.debug('Current call', this.call);
        if (eventData?.added?.length !== 0) {
          logger.debug('Call added', eventData.added);
          [this.call] = eventData.added;
        }
        if (eventData?.removed.length !== 0 && this.call?.id === eventData?.removed[0]?.id) {
          logger.debug('Call removed', eventData?.removed);
          this.call = null;
        }
        this._notify('callsUpdated', this.call);
      });
    } catch (error) {
      logger.error('Error in acs call setup', error);
      throw error;
    }
  }

  /**
   * This method will be used to join call
   * @param {Object} meetingId
   * @param {Object} mediaOptions { muted: boolean }
   */
  joinMeeting(meetingId: TeamsMeetingLinkLocator, mediaOptions = { muted: true }) {
    try {
      this.callAgent?.join(meetingId, { audioOptions: mediaOptions });
    } catch (error) {
      logger.error('Error while joining call in acsCallClient', error);
      throw error;
    }
  }

  /**
   * This method will be used to hangup call
   */
  async hangupCall() {
    try {
      await this.call?.hangUp();
    } catch (error) {
      logger.error('Error while call hangup in acsCallClient', error);
      throw error;
    }
  }

  /**
   * This method will be used to dispose Call
   */
  async disposeCall() {
    try {
      await this.callAgent?.dispose();
      this._cleanUp();
    } catch (error) {
      logger.error('Error while disposing call in acsCallClient', error);
    }
  }

  /**
   * This method use to get call state
   * @returns string {call.State}
   */
  getCallState() {
    if (
      this.call?.state === ACSCallClient.CALL_STATE.none ||
      this.call?.state === ACSCallClient.CALL_STATE.connected ||
      this.call?.state === ACSCallClient.CALL_STATE.disconnected
    ) {
      return this.call?.state;
    }
    return ACSCallClient.CALL_STATE.other;
  }

  /**
   * This method will be used to subscribe for listener
   * @param {string} eventName
   * @param {function} callback
   * @returns string {subscriberId}
   */
  subscribe(eventName: string, callback: any) {
    try {
      let listenersForEvent = this.listeners?.get(eventName);
      if (!listenersForEvent) {
        listenersForEvent = [];
      }
      // We used randomId to give unique identifier for subscriberID
      const randomId = (Math.random() + 1).toString(36).substring(2);
      listenersForEvent.push({
        id: randomId,
        callback,
      });
      this.listeners?.set(eventName, listenersForEvent);

      return randomId;
    } catch (err) {
      logger.error('Error: While subscribing event', eventName, err);
      throw err;
    }
  }

  _notify(eventName: string, eventData: any) {
    try {
      const listenersForEvent = this.listeners?.get(eventName);
      const dataToSend = eventData;
      if (listenersForEvent) {
        listenersForEvent.forEach((callbackObj: any) => {
          callbackObj.callback(dataToSend);
        });
      }
    } catch (error) {
      logger.error('Error: While executing listener', error);
      throw error;
    }
  }

  _cleanUp() {
    this.listeners?.clear();
    this.listeners = null;
    this.call = undefined;
    this.callAgent = undefined;
    this.tokenCredential = undefined;
  }
}
export default ACSCallClient;
