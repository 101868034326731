// Constant
import { CAPTIONER_LANGUAGE_RESOURCE } from 'CONSTANTS/captionerConstants';

// Hooks
import { useEffect, useState } from 'react';

// Resource
import languages from 'RESOURCES/languages';

// Service
import {
  addI18nResources,
  // useTranslation
} from 'SERVICES/i18n';
import logger from 'SERVICES/logger';

const useAppInitializer = () => {
  //   const { i18n } = useTranslation();
  const [isInitialized, setIsInitialized] = useState(false);

  // TODO: Add afeature to change language
  //   // storing language part (not country code) from teams contex
  //   useEffect(() => {
  //     i18n.changeLanguage();
  //   }, []);

  // Initializing App and Localization Service
  useEffect(() => {
    try {
      setIsInitialized(true);

      // Adding caption resources
      addI18nResources(CAPTIONER_LANGUAGE_RESOURCE, languages);
      logger.debug('App initialized');
      setIsInitialized(true);
    } catch (error) {
      logger.error(error);
    }
  }, []);

  return { isInitialized };
};

export default useAppInitializer;
