import { ExclamationCircleIcon, Flex, Text, Tooltip } from '@fluentui/react-northstar';

// Constant
import {
  CAPTIONER_LANGUAGE_RESOURCE,
  TIME_INTERVAL,
  LANGUAGE_PREFIX,
} from 'CONSTANTS/captionerConstants';

// hook
import { useEffect, useRef, useState } from 'react';

// Service
import { useTranslation } from 'react-i18next';

// Uitls
import { ITimer } from 'UTILS/captionerInterface';

import 'COMPONENTS/countDownTimer/CountDownTimer.css';

const CountDownTimer = (timerProps: ITimer) => {
  const { startTime, isCountDown, stopFreeTrial } = timerProps;
  const { t } = useTranslation(CAPTIONER_LANGUAGE_RESOURCE, LANGUAGE_PREFIX);
  const FiveMinutesInSeconds = 300;

  const countDownTimerIntervalId = useRef<NodeJS.Timer | null>(null);
  const [countTime, setCountTime] = useState(startTime);

  // format time value in correnct way
  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secondsLeft = Math.floor(seconds % 60);

    return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${
      secondsLeft < 10 ? `0${secondsLeft}` : secondsLeft
    }`;
  };

  useEffect(() => {
    // for positive timer
    if (!isCountDown) {
      // interval for forward timer
      countDownTimerIntervalId.current = setInterval(() => {
        setCountTime((time) => time + 1);
      }, TIME_INTERVAL.THOUSAND_MS);
    }
    // for reverse countdown
    else {
      // interval for baclkward timer
      countDownTimerIntervalId.current = setInterval(() => {
        setCountTime((time: any) => time - 1);
      }, TIME_INTERVAL.THOUSAND_MS);
    }

    return () => {
      if (countDownTimerIntervalId.current) clearInterval(countDownTimerIntervalId.current);
    };
  }, [isCountDown]);

  // stop countdown timer when reached 0
  useEffect(() => {
    if (countTime === 0 && isCountDown && countDownTimerIntervalId.current) {
      stopFreeTrial();
      clearInterval(countDownTimerIntervalId.current);
    }
  }, [countTime]);

  return (
    <Flex id="credit-timer">
      <Flex column hAlign="center">
        <Text
          content={formatTime(countTime)}
          size="largest"
          weight="semibold"
          className={`counter-text ${
            countTime <= FiveMinutesInSeconds && isCountDown && 'blinking'
          } ${isCountDown && 'count-down'}`}
        />
        <Flex vAlign="center" gap="gap.smaller">
          <Text
            content={isCountDown ? t('TIME_LEFT') : t('MEETING_TIME')}
            weight="bold"
            align="center"
          />
          {isCountDown && (
            <Tooltip
              trigger={<ExclamationCircleIcon outline size="small" />}
              content={t('TIME_LEFT_INFORMATION')}
              position="below"
              align="start"
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

CountDownTimer.defaultProps = {
  isCountDown: false,
};

export default CountDownTimer;
