import {
  API_METHODS,
  COGNITIVE_API_HEADER,
  COGNITIVE_API_PARAMS,
  COGNITIVE_RESOPNSE_TYPE,
} from 'CONSTANTS/apiConstants';
import { ITranslationRequest } from 'CONSTANTS/common-interfaces';
import logger from 'SERVICES/logger';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

class CognitiveService {
  /**
   * This is private function and to be called within the class only
   * @param translationRequest
   * @returns
   */
  async #getTranslations(translationRequest: ITranslationRequest) {
    logger.debug('CognitiveService.#getTranslations');

    try {
      const apiHeaders = COGNITIVE_API_HEADER;

      const translatedResponse = await axios({
        baseURL: process.env.REACT_APP_COGNITIVE_TRANSLATOR_BASE_URL,
        url: process.env.REACT_APP_COGNITIVE_TRANSLATOR_URL,
        method: API_METHODS.POST,
        headers: { ...apiHeaders, 'X-ClientTraceId': uuidv4().toString() },
        params: {
          ...COGNITIVE_API_PARAMS,
          from: translationRequest.fromLanguage,
          to: translationRequest.toLanguages,
        },
        data: [
          {
            text: translationRequest.textToTranslate,
          },
        ],
        responseType: COGNITIVE_RESOPNSE_TYPE.JSON,
      });

      logger.debug('CognitiveService.#getTranslations Response', translatedResponse);

      return translatedResponse.data[0].translations;
    } catch (error) {
      logger.error('CognitiveService.#getTranslations Error', error);
      throw error;
    }
  }

  /**
   * This is public function and to be called from outside the class
   * @param translationRequest
   * @returns Translated data including the original text
   */
  async getTranslatedData(translationRequest: ITranslationRequest, speaker: string) {
    try {
      logger.debug('CognitiveService.getTranslatedData', translationRequest);
      const allLanguagesData = await this.#getTranslations(translationRequest);
      const captionsObj: any = {};
      allLanguagesData?.forEach((element: any) => {
        captionsObj[element.to] = element?.text;
      });
      captionsObj[translationRequest.fromLanguage] = translationRequest.textToTranslate;
      return { speaker, captions: captionsObj };
    } catch (error) {
      logger.error('CognitiveService.getTranslatedData Error', error);
      throw error;
    }
  }
}

export default CognitiveService;
