import { Provider, teamsTheme } from '@fluentui/react-northstar';
import Snackbar from 'COMPONENTS/snackbar/Snackbar';
import { ReactNode } from 'react';
import 'RESOURCES/theme/ThemeProvider.css'

// provides basic theme for Fluent UI components
const ThemeProvider = ({ children }: { children: ReactNode }) => {
  return (
    <Provider theme={teamsTheme} className='theme-wrapper'>
      <Snackbar />
      {children}
    </Provider>
  );
};

export default ThemeProvider;
